import app from './url/app'
import game from './url/game'
import login from './url/login'
import mistakes from './url/mistakes'
import personal from './url/personal'
import subject from './url/subject'
import task from './url/task'
import user from './url/user'
import avatar from './url/avatar'
import history from './url/history'
import taskcenter from './url/taskcenter'
import gameReview from './url/gameReview'
import certificate from './url/certificate'
import cabin from './url/cabin'
import share from './url/share'
import websocket from './url/websocket'
import socialGame from './url/socialGame'
import exercise from './url/mind/exercise'
import preview from './url/preview'
import mall from './url/mind/mall'
import mindGame from './url/mind/mindGame'
import palace from './url/mind/palace'
import activate from './url/mind/activate'
import flashGame from './url/mind/flashGame'



export default {
    game,
    login,
    app,
    mistakes,
    personal,
    subject,
    task,
    user,
    avatar,
    history,
    taskcenter,
    gameReview,
    certificate,
    cabin,
    share,
    websocket,
    socialGame,
    exercise,
    preview,
    mall,
    mindGame,
    palace,
    activate,
    flashGame,
    _global: {
        'config': {
            url: '/town/resource',
            serve: 'social',
            login: true
        },
        'config_puzzle': {
            url: '/town/puzzle/label/1',
            serve: 'social',
            login: true
        },
        'userAccount': {
            url: '/town/school/user/account/{user_code}',
            serve: 'social',
        },
        'balanceCheck': {
            url: '/town/school/user/balance/check/{user_code}',
            serve: 'social',
            login: true
        },
        'allExercise': {
            url: '/town/import/exercise/list/{type}',
            serve: 'social',
            login: true
        },
        'updateExercise': {
            url: '/town/import/exercise/formula_content',
            serve: 'social',
            login: true
        },
        'userLevel': {
            url: '/infos/level/{username}',
            serve: 'engine',
            login: true
        },
        'version': {
            url: '/info/version/web',
            serve: 'engine',
            login: false
        },
        'wsUserInfoGlobal': {
            url: '/wsuser/{usercode}',
            serve: 'social'
        },
        //设置用户状态
        'setUserStatus': {
            // url: '/gamezone/user/status/{usercode}'
            url: '/wsuser/status/{usercode}'
        },
        // 人人对弈等级列表
        // 'levelList': {
        //     login: false,
        //     url: ''
        // },
    }
}