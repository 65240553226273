import params from './params'
import commonFun from './commonFun'
import userHand from './userHand'
import {singleProxy} from './exportEnter'
import {Howl, Howler} from 'howler'

let ASSETSURL =  params.assetsUrl_root
let ASSETSURLGAOTU =  params.assetsUrl
class voiceHand {
    constructor(opt){
        this._common = new commonFun()
        this._userHand = new userHand()
        this.init(opt)
    }
    init(items){
        items = items || {}
        this.HowlList = {
            // 'stone': new Howl({src: [`${ASSETSURL}/voice/play.mp3`]}),
            // 'remove0':new Howl({src: [`${ASSETSURL}/voice/remove0.mp3`]}),
            // 'remove1':new Howl({src: [`${ASSETSURL}/voice/remove1.mp3`]}),
            // 'remove2':new Howl({src: [`${ASSETSURL}/voice/remove2.mp3`]}),
            // 'remove3':new Howl({src: [`${ASSETSURL}/voice/remove2.mp3`]}),
            // 'remove4':new Howl({src: [`${ASSETSURL}/voice/remove4.mp3`]}),
            // 'ex_right': new Howl({src: [`${ASSETSURLGAOTU}/voice/ex_right.mp3`]}),
            // 'ex_wrong': new Howl({src: [`${ASSETSURLGAOTU}/voice/ex_wrong.mp3`]}),
            // 'game start': new Howl({src: [`${ASSETSURLGAOTU}/voice/v3_0/gameStart.mp3`]}),
            // 'game start word': new Howl({src: [`${ASSETSURL}/voice/gameStart.wav`]}),
            // 'home bcg': new Howl({src: [`${ASSETSURLGAOTU}/voice/v3_0/homeBcg.mp3`], loop: true, config: 'backgroundMusic'}),
            // 'index button': new Howl({src: [`${ASSETSURLGAOTU}/voice/v3_0/indexButton.mp3`]}),
            // 'puzzle end': new Howl({src: [`${ASSETSURLGAOTU}/voice/v3_0/puzzleEnd.mp3`]}),

            // 'count down start': new Howl({src: [`${ASSETSURL}/voice/start.wav`]}),

            // 'game win': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/win.wav`]}),
            // 'game lose': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/lose.mp3`]}),

            // 'game time out': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/timeout.wav`]}),

            // 'countdownSecond1': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec1.wav`]}),
            // 'countdownSecond2': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec2.wav`]}),
            // 'countdownSecond3': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec3.wav`]}),
            // 'countdownSecond4': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec4.wav`]}),
            // 'countdownSecond5': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec5.wav`]}),
            // 'countdownSecond6': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec6.wav`]}),
            // 'countdownSecond7': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec7.wav`]}),
            // 'countdownSecond8': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec8.wav`]}),
            // 'countdownSecond9': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec9.wav`]}),
            // 'countdownSecond10': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec10.wav`]}),

            // 'countdown1': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last1.wav`]}),
            // 'countdown2': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last2.wav`]}),
            // 'countdown3': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last3.wav`]}),
            // 'countdown4': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last4.wav`]}),
            // 'countdown5': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last5.wav`]}),
            // 'countdown6': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last6.wav`]}),
            // 'countdown7': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last7.wav`]}),
            // 'countdown8': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last8.wav`]}),
            // 'countdown9': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last9.wav`]}),

            // 'color_black': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/color_black.wav`]}),
            // 'color_white': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/color_white.wav`]}),
            // 'user_limited': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/board/user_limited.mp3`]}),
            // 'taste_level_disabled': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste_level_disabled.mp3`]}),
            // 'taste_success': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste_success.wav`]}),
            // 'enter_delay': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/enter_delay.mp3`]}),
            // 'taste': new Howl({src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste.mp3`], loop: true}),

            // 'game sudoku action': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/sudoku.wav`], loop: true }),
            // 'game schulte action': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/schulte.wav`], loop: true }),
            // 'game arithmetic action': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/arithmetic.wav`], loop: true }),
            // 'game memory action': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/memory.wav`], loop: true }),
            // 'game cubepuzzle action': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/cubepuzzle.wav`], loop: true }),

            // 'mind game click': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/click.wav`]}),
            // 'mind game correct': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/correct.wav`]}),
            // 'mind game error': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/error.wav`]}),
            // 'mind game success': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/success.wav`]}),
            // 'mind game failed': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/failed.wav`]}),
            // 'mind game special': new Howl({ src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/special.wav`]}),
        }
        this.extendVoice = {
            'game bcg': {src: [`${ASSETSURLGAOTU}/voice/v3_0/gameBcg.mp3`], loop: true},
            'subject bcg': {src: [`${ASSETSURLGAOTU}/voice/v3_0/subjectBcg.mp3`], loop: true},




            'stone': {src: [`${ASSETSURL}/voice/play.mp3`]},
            'remove0':{src: [`${ASSETSURL}/voice/remove0.mp3`]},
            'remove1':{src: [`${ASSETSURL}/voice/remove1.mp3`]},
            'remove2':{src: [`${ASSETSURL}/voice/remove2.mp3`]},
            'remove3':{src: [`${ASSETSURL}/voice/remove2.mp3`]},
            'remove4':{src: [`${ASSETSURL}/voice/remove4.mp3`]},
            'ex_right': {src: [`${ASSETSURLGAOTU}/voice/ex_right.mp3`]},
            'ex_wrong': {src: [`${ASSETSURLGAOTU}/voice/ex_wrong.mp3`]},
            'game start': {src: [`${ASSETSURLGAOTU}/voice/v3_0/gameStart.mp3`]},
            'game start word': {src: [`${ASSETSURL}/voice/gameStart.wav`]},
            'home bcg': {src: [`${ASSETSURLGAOTU}/voice/v3_0/homeBcg.mp3`], loop: true, config: 'backgroundMusic'},
            'index button': {src: [`${ASSETSURLGAOTU}/voice/v3_0/indexButton.mp3`]},
            'puzzle end': {src: [`${ASSETSURLGAOTU}/voice/v3_0/puzzleEnd.mp3`]},

            'count down start': {src: [`${ASSETSURL}/voice/start.wav`]},

            'game win': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/win.wav`]},
            'game lose': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/lose.mp3`]},

            'game time out': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/timeout.wav`]},

            'countdownSecond1': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec1.wav`]},
            'countdownSecond2': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec2.wav`]},
            'countdownSecond3': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec3.wav`]},
            'countdownSecond4': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec4.wav`]},
            'countdownSecond5': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec5.wav`]},
            'countdownSecond6': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec6.wav`]},
            'countdownSecond7': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec7.wav`]},
            'countdownSecond8': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec8.wav`]},
            'countdownSecond9': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec9.wav`]},
            'countdownSecond10': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/readSec10.wav`]},

            'countdown1': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last1.wav`]},
            'countdown2': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last2.wav`]},
            'countdown3': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last3.wav`]},
            'countdown4': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last4.wav`]},
            'countdown5': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last5.wav`]},
            'countdown6': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last6.wav`]},
            'countdown7': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last7.wav`]},
            'countdown8': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last8.wav`]},
            'countdown9': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/last9.wav`]},

            'color_black': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/color_black.wav`]},
            'color_white': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/color_white.wav`]},
            'user_limited': {src: [`${ASSETSURL}/gaotu/voice/v3_0/board/user_limited.mp3`]},
            'taste_level_disabled': {src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste_level_disabled.mp3`]},
            'taste_success': {src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste_success.wav`]},
            'enter_delay': {src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/enter_delay.mp3`]},
            'taste': {src: [`${ASSETSURL}/gaotu/voice/v3_0/ws_game/taste.mp3`], loop: true},

            'game sudoku action': { src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/sudoku.wav`], loop: true },
            'game schulte action': { src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/schulte.wav`], loop: true },
            'game arithmetic action': { src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/arithmetic.wav`], loop: true },
            'game memory action': { src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/memory.wav`], loop: true },
            'game cubepuzzle action': { src: [`${ASSETSURLGAOTU}/voice/mind_game/bcg_music/cubepuzzle.wav`], loop: true },

            'mind game click': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/click.wav`]},
            'mind game correct': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/correct.wav`]},
            'mind game error': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/error.wav`]},
            'mind game success': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/success.wav`]},
            'mind game failed': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/failed.wav`]},
            'mind game special': { src: [`${ASSETSURLGAOTU}/voice/mind_game/sound/special.wav`]},

            'flash game click': { src: [`${ASSETSURLGAOTU}/voice/flash_game/click.wav`]},
            'flash game drag success': { src: [`${ASSETSURLGAOTU}/voice/flash_game/drag_success.wav`]},
            'flash game drag failed': { src: [`${ASSETSURLGAOTU}/voice/flash_game/drag_failed.wav`]},
        }
        this.voiceControlKeys = {
            'home bcg': 'backgroundMusic',
            'subject bcg': 'backgroundMusic',
            'game bcg': 'backgroundMusic',
            'game start': 'sound',
            'ex_right': 'sound',
            'ex_wrong': 'sound',
            'index button': 'sound',
            'remove': 'playVoice',
            'stone': 'playVoice',
            'puzzle end': 'sound',
            'pet': 'sound'
        }
        for(let i in items){
            this[i] = items[i]
        }
        this.eventList = {}
        
        this.voiceList = {
            'play': { id: 'play', path: `${ASSETSURL}/voice/play.mp3`, config: 'playVoice' },
            'timeout': { id: 'timeout', path: `${ASSETSURL}/gaotu/voice/v3_0/board/timeout.mp3` },
            'last': { id: 'timeout', path: `${ASSETSURL}/gaotu/voice/v3_0/board/last` },
            'start': { id: 'timeout', path: `${ASSETSURL}/voice/start.mp3` },
            'remove1': { id: 'remove1', path: `${ASSETSURL}/voice/remove1.mp3` },
            'remove2': { id: 'remove2', path: `${ASSETSURL}/voice/remove2.mp3` },
            'remove3': { id: 'remove2', path: `${ASSETSURL}/voice/remove2.mp3` },
            'remove4': { id: 'remove4', path: `${ASSETSURL}/voice/remove4.mp3` },
            'remove0': { id: 'remove0', path: `${ASSETSURL}/voice/remove0.mp3` },
            // 'readSec0': {id: 'readSec0', path: `${ASSETSURL}/voice/readSec0.mp3`},
            'readSec1': { id: 'readSec1', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec1.mp3` },
            'readSec2': { id: 'readSec2', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec2.mp3` },
            'readSec3': { id: 'readSec3', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec3.mp3` },
            'readSec4': { id: 'readSec4', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec4.mp3` },
            'readSec5': { id: 'readSec5', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec5.mp3` },
            'readSec6': { id: 'readSec6', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec6.mp3` },
            'readSec7': { id: 'readSec7', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec7.mp3` },
            'readSec8': { id: 'readSec8', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec8.mp3` },
            'readSec9': { id: 'readSec9', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec9.mp3` },
            'readSec10': { id: 'readSec10', path: `${ASSETSURL}/gaotu/voice/v3_0/board/readSec10.mp3` },
            'gameStart': { id: 'gameStart', path: `${ASSETSURL}/voice/gameStart.mp3` },
            'tool': { id: 'tool', path: `${ASSETSURL}/voice/` },
            'result': { id: 'result', path: `${ASSETSURL}/voice/` },
            'volume': { id: 'volume', path: `${ASSETSURL}/voice/volume.mp3` },
            'branch1': { id: 'branch1', path: `${ASSETSURL}/voice/play.mp3` },
            'branch2': { id: 'branch1', path: `${ASSETSURL}/voice/branch_2.mp3` },
            'branch3': { id: 'branch2', path: `${ASSETSURL}/voice/branch_3.mp3` },
            'branch4': { id: 'branch3', path: `${ASSETSURL}/voice/branch_4.mp3` },
            'branch7': { id: 'branch4', path: `${ASSETSURL}/voice/branch_7.mp3` },
            'branch8': { id: 'branch8', path: `${ASSETSURL}/voice/branch_8.mp3` },
            'branchMove': { id: 'branchMove', path: `${ASSETSURL}/voice/branch_move.mp3` },
            'ex_right': { id: 'ex_right', path: `${ASSETSURLGAOTU}/voice/ex_right.mp3`, config: 'sound'},
            'ex_wrong': { id: 'ex_right', path: `${ASSETSURLGAOTU}/voice/ex_wrong.mp3`, config: 'sound'},
            'puzzleEnd': {id: 'puzzleEnd', path: `${ASSETSURLGAOTU}/voice/puzzleEnd.mp3`, config: 'sound'},
            'invite': { id: 'invite', path: `${ASSETSURL}/voice/invite.mp3` },
            //game config changed
            'configChange': { id: 'configChange', path: `${ASSETSURL}/voice/configChange.wav` },
            'actionReject': { id: 'actionReject', path: `${ASSETSURL}/voice/actionReject.wav` },
        }
    }
    pauseAll(){
        let list = this.HowlList
        for(let i in list){
            let obj = list[i]
            if(!obj || !obj.stop) continue
            obj.stop()
        }
    }
    audioJarCon(type) {
        type = type || 'play'
        let jar = {
            'play': [],
            'second': ['play', 'status'],
            'status': ['play'],
            'exercise': ['exercise']
        }
        let data = jar[type], f = true
        if (data == undefined) return f
        for (var i in data) {
            if (this.audioStatusSet[data[i]]) {
                f = false
                break
            }
        }
        return f
    }

    playAction(type, opt, params) {
        // if (!this[`${type}Play`] || !this[this.audioObj[type]]) return
        if (!this[`${type}Play`]) return
        this[`${type}Play`](opt, params)
    }

    setPath(src, audio) {
        // audio = audio || this.audio
        // audio.setAttribute('src', src);
    }
    audioPlay(audio, type) {
        // type = type || 'play'
        // if (!this.audioJarCon(type)) return
        // for (var i in this.audioStatusSet) {
        //     if (i == type) this.audioStatusSet[i] = true
        //     else this.audioStatusSet[i] = false
        // }
        // audio = audio || this.audio
        // audio.muted = false
        // this.audio.pause()
        // this.audioSecond.pause()
        // this.audioStatus.pause()
        // this.audioExercise.pause()
        // audio.play()
    }
    audioInit(elementPlay, elementSecond, elementStatus, exerciseVoice) {
        this.audio = elementPlay
        this.audioSecond = elementSecond
        this.audioStatus = elementStatus
        this.audioExercise = exerciseVoice
        this.audio.addEventListener('ended', () => {
            this.audioStatusSet.play = false
        })
        this.audioSecond.addEventListener('ended', () => {
            this.audioStatusSet.second = false
        })
        this.audioStatus.addEventListener('ended', () => {
            this.audioStatusSet.status = false
        })
        this.audioExercise.addEventListener('ended', () => {
            this.audioStatusSet.exercise = false
        })
    }
    async removePlay(num, params) {
        if(!this.configCheck('remove')) return
        params = params || {}
        let timeout = 300
        if (params.timeout !== undefined) timeout = params.timeout
        await this._common.settimeout(timeout)
        let obj = this.getHowObj(`remove${num}`)
        if(!obj) obj = this.getHowObj(`remove0`)
        if(!obj) return
        obj.play()
    }
    stonePlay() {
        if(!this.configCheck('stone')) return
        let obj = this.getHowObj(`stone`)
        if(!obj) return
        obj.play()
    }
    readPlay(num) {
        let data = this.voiceList[`readSec${num}`] || null
        if (!data) return
        this.setPath(data.path, this.audioSecond)
        this.audioPlay(this.audioSecond, 'second')
    }
    timeoutPlay() {
        let data = this.voiceList['timeout'] || null
        if (!data) return
        this.setPath(data.path, this.audioStatus)
        this.audioPlay(this.audioStatus, 'status')
    }
    startPlay() {
        let data = this.voiceList['start'] || null
        if (!data) return
        this.setPath(data.path, this.audioStatus)
        this.audioPlay(this.audioStatus, 'status')
    }
    lastPlay(num) {
        num = num || ''
        let data = this.voiceList['last'] || null
        if (!data) return
        let path = data.path + num + '.mp3'
        this.setPath(path, this.audioStatus)
        this.audioPlay(this.audioStatus, 'status')
    }
    gameStartPlay() {
        let data = this.voiceList['gameStart']
        this.setPath(data.path)
        this.audioPlay()
    }
    toolPlay(d) {
        let data = this.voiceList['tool']
        if (!data) return
        this.setPath(data.path + d + '.mp3')
        this.audioPlay()
    }
    resultPlay(d) {
        let data = this.voiceList['result']
        if (!data) return
        this.setPath(data.path + d + '.mp3')
        this.audioPlay()
    }
    volumePlay(d) {
        let data = this.voiceList['volume']
        this.setPath(data.path)
        this.audioPlay()
    }
    branchPlay(num) {
        let data = this.voiceList['branch' + num] || this.voiceList['branch1']
        this.setPath(data.path)
        this.audioPlay()
    }
    branchMovePlay() {
        let data = this.voiceList['branchMove']
        this.setPath(data.path)
        this.audioPlay()
    }

    //练习
    exercisePlay(type){
        type = `ex_${type}`
        if(!this.configCheck(type)) return
        let obj = this.getHowObj(type)
        if(!obj) return
        obj.play()
    }
    
    normalPlay(type){
        if(!this.configCheck(type)) return
        let obj = this.getHowObj(type)
        if(!obj) return
        obj.play()
    }
    normalStop(type){
        if(type === undefined) return this.stopAll()
        let obj = this.getHowObj(type)
        if(!obj) return
        obj.stop()
    }
    stopAll(){
        let list = this.HowlList
        for(let i in list){
            if(!list[i].stop) continue
            list[i].stop()
        }
    }
    configCheck(type){
        let config = this.voiceControlKeys[type] || {}
        if(!config) return true
        let data = this._userHand.getUserSetting() || {}
        if(data[config] === undefined) return true
        return data[config] ? true : false
    }
    playByPath(path, type){
        type = type || 'pet'
        if(!this.configCheck(type)) return
        let obj = this.HowlList[path]
        if(!obj) obj = new Howl({src: [path]})
        if(!obj || !obj.play) return
        obj.pause()
        obj.play()
        this.HowlList[path] = obj
    }
    pauseByPath(path){
        if(!this.HowlList[path] || !this.HowlList[path].pause) return
        this.HowlList[path].pause()
    }
    getHowObj(type){
        if(!this.HowlList[type] && this.extendVoice[type]){
            let params = this.extendVoice[type]
            this.HowlList[type] = new Howl(params)
        }
        return this.HowlList[type]
    }
}

export default singleProxy(voiceHand)